import React from "react";
import Header from "../components/header/header";
import PreStay from "../assets/img/pre-stay.png";
import DuringStay from "../assets/img/during-stay.png";
import PostStay from "../assets/img/post-stay.png";

import Star from "../assets/img/star.png";
import LittleBall from "../assets/img/pinkball.png";
import Cube from "../assets/img/cube.png";
import Pyramid from "../assets/img/pyramid.png";

import "../assets/styles/pages/_how-it-works.scss";

const PageProps = {
  title: "Come funziona",
  tagline: "Come funziona",
  body: "Eucleia è dedicato a tutti i tipi di business, monitora l’esperienza del tuo cliente prima, durante e dopo la sua presenza all’interno della tua attività.",
  metaDescription: "",
  banner: "binoculars.png",
  alt: "Binocolo",
  to: "/",
};

function showElement(id, idBtn) {
  let infoStay = document.getElementsByClassName("infoStay");
  let btnStay = document.getElementsByClassName("btnStay");
  let arrayInfoStay = [...infoStay];
  let arrayBtnStay = [...btnStay];
  arrayInfoStay.map(
    (i) => (i.className = "columns is-centered infoStay hidden")
  );
  arrayBtnStay.map((i) => (i.className = "btnStay"));
  let el = document.getElementById(id);
  let btn = document.getElementById(idBtn);
  el.className = "columns is-centered infoStay unhidden";
  btn.className = "btnStay active";
}

const INFOSTAY = [
  {
    id: "preStay",
    title: "Pre Stay",
    icon: PreStay,
    body: `Crea un rapporto privilegiato con i tuoi clienti sin dal momento della richiesta di appuntamento o della prenotazione per il loro soggiorno.
          Interagisci da subito:`,
    list: [
      "Conferma l’avvenuta prenotazione.",
      "Informali su tutti i servizi disponibili, orari di apertura, e sul modo più veloce per raggiungere la tua attività.",
    ],
    listHotellerie: [
      "Informa il tuo ospite sugli orari di checkIN",
      "Comunicagli l’eventualità di poterlo effettuare online, grazie all’integrazione con Book@Me",
      "Presenta il tuo team",
      "Fornisci indicazioni su come raggiungere la struttura o gli orari della colazione, offri informazioni su itinerari, su attrazioni locali e i vari eventi o manifestazioni da non perdere.",
    ],
    status: "unhidden",
  },
  {
    id: "duringStay",
    title: "During Stay",
    icon: DuringStay,
    body: `Tramite l’invio di una survey, potrai sondare l’andamento della visita del tuo cliente, risolvendo in tempo reale e con la massima tempestività eventuali problematiche`,
    list: [
      "È possibile inserire il numero di telefono di un eventuale referente, che sarà a disposizione per la risoluzione di problematiche o per semplici richieste di informazioni.",
      "Con Eucleia sarà come avere un concierge a disposizione per ogni cliente!",
    ],
    listHotellerie: null,
    status: "hidden",
  },
  {
    id: "postStay",
    title: "Post Stay",
    icon: PostStay,
    body: `Coinvolgi i tuoi clienti ad esprimere un giudizio sulla tua attività commerciale e sulla loro esperienza, tramite l’invio di un questionario da compilare. 
          Inoltre, offrigli la possibilità di condividere recensioni positive sul sito/social da loro preferito! (Google, Facebook, Trustpilot, ecc.)`,
    list: [],
    listHotellerie: [
      "Al termine del soggiorno tramite i nostri questionari rileva la Customer Satisfaction, all’interno del nostro backoffice potrai leggere le recensioni ottenute su Booking.com, e se vorrai potrai rispondere e commentare.",
    ],
    status: "hidden",
  },
];

const HowItWorks = () => {
  return (
    <>
      <Header {...PageProps} />
      <div className="howItWorks">
        <div className="section" />
        <div className="columns">
          <div className="column youCanChoose is-offset-3 is-6">
            <div className="container">
              <h2>
                Potrai scegliere quando comunicare con il tuo cliente tramite
                l’invio tramite mail e/o SMS in tre diversi momenti:
              </h2>
            </div>
          </div>
        </div>
        <div className="columns stay is-centered">
          <div className="column is-3-widescreen is-2-fullhd">
            <div className="container">
              <img src={PreStay} alt="Pre-stay Icon" />
              <h2>Pre Stay</h2>
              <p>Dopo la prenotazione di un appuntamento o di un soggiorno</p>
            </div>
          </div>
          <div className="column is-3-widescreen is-2-fullhd">
            <div className="container">
              <img src={DuringStay} alt="During-stay Icon" />
              <h2>During Stay</h2>
              <p>Durante la permanenza nella tua attività commerciale</p>
            </div>
          </div>
          <div className="column is-3-widescreen is-2-fullhd">
            <div className="container">
              <img src={PostStay} alt="Post-stay Icon" />
              <h2>Post Stay</h2>
              <p>
                Dopo che il cliente abbia lasciato la tua attività commerciale
              </p>
            </div>
          </div>
        </div>
        <div className="darkBlue">
          <div className="section" />
          <div className="columns is-centered">
            <div className="column is-3-widescreen is-2-fullhd">
              <div className="container">
                <button
                  id="preStayBtn"
                  className="btnStay active"
                  onClick={() => showElement("preStay", "preStayBtn")}
                >
                  Pre Stay
                </button>
              </div>
            </div>
            <div className="column is-3-widescreen is-2-fullhd">
              <div className="container">
                <button
                  id="duringStayBtn"
                  className="btnStay"
                  onClick={() => showElement("duringStay", "duringStayBtn")}
                >
                  During Stay
                </button>
              </div>
            </div>
            <div className="column is-3-widescreen is-2-fullhd">
              <div className="container">
                <button
                  id="postStayBtn"
                  className="btnStay"
                  onClick={() => showElement("postStay", "postStayBtn")}
                >
                  Post Stay
                </button>
              </div>
            </div>
          </div>
          <div className="section" />

          {INFOSTAY.map((i, index) => (
            <div
              id={i.id}
              key={index}
              className={`columns is-centered infoStay ${i.status}`}
            >
              <div className="column is-4">
                <p>
                  {i.body}
                  <br />
                  <br />
                </p>
                <ul>
                  {i.list.map((i, index) => (
                    <li key={index}>
                      {i}
                      <br />
                      <br />
                    </li>
                  ))}
                </ul>
                {i.listHotellerie && (
                  <>
                    <br />
                    <br />
                    <h4>
                      Settore Hôtellerie
                      <br />
                      <br />
                    </h4>
                    <ul>
                      {i.listHotellerie.map((i, index) => (
                        <li key={index}>
                          {i}
                          <br />
                          <br />
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
              <div className="column is-4">
                <div className="container">
                  <img id="cube" src={Cube} alt={`${i.title} Icon`} />
                  <img id="littleBall" src={LittleBall} alt={`${i.title} Icon`} />
                  <img id="pyramid" src={Pyramid} alt={`${i.title} Icon`} />
                  <img id="star" src={Star} alt={`${i.title} Icon`} />
                  <img src={i.icon} alt={`${i.title} Icon`} />
                </div>
              </div>
            </div>
          ))}

          <div className="section" />
          <div className="section" />
          <div className="container">
            <div className="columns">
              <div className="column">
                <br />
                <br />
                <h4>Risultato?</h4>
                <br />
                <p>
                  Un sicuro aumento della Brand Reputation in rete ed un
                  conseguente incremento delle visite al sito web o alla pagina
                  social collegata, generato da un accrescimento delle
                  recensioni positive, che inoltre, favorirà un incremento di
                  clienti per la tua attività commerciale.
                </p>
                <br />
                <br />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="columns">
              <div className="column">
                <br />
                <br />
                <h4>Questionari personalizzati e non obbligatori</h4>
                <br />
                <p>
                  Nel during e/o nel post stay, il software è predisposto per
                  l’invio automatico di un questionario che il cliente potrà
                  compilare anche parzialmente (senza campi obbligatori). Il
                  questionario è completamente personalizzabile nelle domande,
                  nelle categorie e nelle sottocategorie da far valutare al
                  cliente. Al termine del questionario, se la recensione sarà
                  positiva, verrà fornita la possibilità di lasciare il proprio
                  giudizio online. Nel canale scelto, ad esempio su Tripadvisor.
                  Per il settore hospitality, è possibile integrare Eucleia
                  attraverso le nostre API con i principali software di booking
                  engine+channel manager e gestionali alberghieri.
                </p>
                <br />
                <br />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="columns">
              <div className="column">
                <br />
                <h4>Personalizzazione ed affidabilità email &amp; sms</h4>
                <br />
                <p>
                  La personalizzazione per l’invio di mail o SMS è semplice ed
                  avviene attraverso un editor di testo, nel quale è possibile
                  inserire anche dei link che possono reindirizzare il cliente
                  su URL dedicate, per informarlo su attività, promozioni, ecc.,
                  inerenti alla tua attività commerciale. Al momento
                  dell’attivazione, nel backoffice troverai dei testi
                  predefiniti in italiano ed inglese, che in seguito sarà
                  possibile modificare in completa autonomia.
                </p>
                <br />
                <br />
              </div>
            </div>
          </div>
          <div className="section" />
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
